export class Validator {
    constructor(data) {
        this.data = data
        this.errors = {}
    }

    checkNotBlank(name) {
        if (Validator.isBlank(this.#value(name))) {
            this.addError(name, "Die Eingabe ist zwingend")
        }
    }

    checkNotEmpty(name) {
        if (Validator.isEmpty(this.#value(name))) {
            this.addError(name, "Die Eingabe ist zwingend")
        }
    }

    checkAmount(name) {
        if (!Validator.isAmount(this.#value(name))) {
            this.addError(name, "Ungültiger Betrag (ganze Zahl ≥ 0 erwartet)")
        }
    }

    checkZipPlace(name) {
        if (!Validator.isZipPlace(this.#value(name))) {
            this.addError(name, "Die Eingabe muss aus Postleitzahl UND Ort bestehen")
        }
    }

    checkEmail(name) {
        if (!Validator.isEmail(this.#value(name))) {
            this.addError(name, "Ungültige E-Mail-Adresse")
        }
    }

    checkPhone(name) {
        if (!Validator.isPhone(this.#value(name))) {
            this.addError(name, "Ungültige Telefonnummer")
        }
    }

    checkYearOfBirth(name) {
        if (!Validator.isYearOfBirth(this.#value(name))) {
            this.addError(name, "Ungültiges Geburtsjahr (vierstellige Zahl >= 1900 erwartet)")
        }
    }

    checkLength(name, min, max = Number.MAX_SAFE_INTEGER) {
        const length = String(this.#value(name)).length
        if (length < min) {
            this.addError(name, `Mindestens ${min} Zeichen erwartet`)
        } else if (length > max) {
            this.addError(name, `Höchstens ${max} Zeichen erlaubt`)
        }
    }

    addError(name, message) {
        if (!this.errors[name]) {
            this.errors[name] = message
        }
    }

    hasErrors() {
        return Object.keys(this.errors).length !== 0
    }

    error(name) {
        return this.errors[name] ?? null
    }

    allErrors() {
        return this.errors
    }

    static isBlank(value) {
        return typeof value === "string" && value.trim() === ""
            || typeof value === "undefined"
            || value === null
    }

    static isNotBlank(value) {
        return !Validator.isBlank(value)
    }

    static isEmpty(value) {
        return typeof value === "string" && value === ""
            || typeof value === "undefined"
            || value === null
    }

    static isNotEmpty(value) {
        return !Validator.isEmpty(value)
    }

    static isAmount(value) {
        return String(value).match(/^\d+$/)
    }

    static isZipPlace(value) {
        return String(value).trim().match(/^\d+\s+(\S.+)$/)
    }

    static isEmail(value) {
        return String(value).trim().match(/^[^@ ]+@[^@ ]+\.[a-z]{2,4}$/)
    }

    static isPhone(value) {
        return String(value).trim().match(/^\+?[\d ]+$/)
    }

    static isYearOfBirth(value) {
        return String(value).match(/^\d{4}$/) && value >= 1900
    }

    #value(name) {
        const parts = name.split(".")
        let value = this.data
        for (const part of parts) {
            value = value[part]
        }
        return value
    }
}