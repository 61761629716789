<script>
    import {beforeUpdate} from "svelte";

    export let validator
    export let name

    let message

    beforeUpdate(() => message = validator.error(name));
</script>

{#if message}
    <div class="input-error {$$props.class}">{message}</div>
{/if}