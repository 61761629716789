<script>
    export let files = []
    export let disabled = false

    const acceptedFileTypes = ["image/jpeg", "image/png", "application/pdf", ".jpg", ".png", ".pdf", ".zip"]

    let mainElem
    let fileInputElem
    let dragging = false

    function onAddFiles() {
        fileInputElem.click()
    }

    function onFilesChanged(e) {
        addFiles(e.target.files);
    }

    function onDragEnter(e) {
        dragging = mainElem.contains(e.target)
    }

    function onDragOver(e) {
        e.dataTransfer.dropEffect = mainElem.contains(e.target) ? "copy" : "none"
    }

    function onDrop(e) {
        dragging = false
        if (mainElem.contains(e.target)) {
            addFiles(e.dataTransfer.files)
        }
    }

    function onRemoveFile(atIndex) {
        files.splice(atIndex, 1)
        files = files
    }

    function addFiles(newFiles) {
        newFiles = Array.from(newFiles)
            .filter(f => isAcceptedFile(f))
            .map(f => prepareFile(f))
        const filesWithoutNewFiles = files.filter(f => !newFiles.some(nf => f.name === nf.name))
        files = filesWithoutNewFiles.concat(newFiles)
    }

    function prepareFile(file) {
        file.sizeKb = Math.round(file.size / 1024)
        const reader = new FileReader()
        reader.onload = () => file.data = reader.result
        reader.readAsDataURL(file)
        return file
    }

    function isAcceptedFile(file) {
        if (acceptedFileTypes.includes(file.type)) {
            return true
        }
        for (const type of acceptedFileTypes) {
            if (type.startsWith(".") && file.name.endsWith(type)) {
                return true
            }
        }
        return false
    }
</script>

<svelte:window on:dragenter|preventDefault={onDragEnter}
               on:dragover|preventDefault={onDragOver}
               on:drop|preventDefault={onDrop}/>

<div class="upload-images" class:dragging={dragging} bind:this={mainElem}>
    <div class="images">
        {#each files as file, index}
            <div class="file" class:disabled>
                <div class="name">{file.name}</div>
                <div class="size">({file.sizeKb} KB)</div>
                <button type="button" class="remove" on:click={() => onRemoveFile(index)} {disabled}>Remove</button>
            </div>
        {:else}
            <span class="drag-hint">
                Dateien hierher ziehen oder über den «Dateien hinzufügen»-Knopf auswählen.<br>
                Es werden nur PDF-Dokumente oder Bilder (JPG, PNG) akzeptiert.
            </span>
        {/each}
    </div>
</div>

<div class="buttons">
    <button type="button" class="button-add" on:click={onAddFiles} {disabled}>Dateien hinzufügen</button>
</div>

<input type="file" multiple accept={acceptedFileTypes.join(",")} style="display: none"
       on:change={onFilesChanged} bind:this={fileInputElem} />
