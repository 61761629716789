<script context="module">
	const amountLevels = [
		{id: 1, from: 100, to: 300, text: "100–300 Franken", description: { min: 600, max: 800, hint: "600–800 Zeichen (inkl. Leerzeichen)" }},
		{id: 2, from: 300, to: 1000, text: "300–1000 Franken", description: { min: 1200, max: 2000, hint: "1200–2000 Zeichen (inkl. Leerzeichen)" }},
		{id: 3, from: 1000, to: 2000, text: "1000–2000 Franken", description: { min: 3000, max: 4000, hint: "3000–4000 Zeichen (inkl. Leerzeichen)" }},
		{id: 4, from: 2000, to: 10000000000, text: "Mehr als 2000 Franken", description: { min: 7000, max: 1000000, hint: "Mindestens 7000 Zeichen (inkl. Leerzeichen)" }},
	]
</script>

<script>
	import {tick} from "svelte"
	import {scrollIntoView, toFloatOrNull, toInt, toIntOrNull, trimToNull} from "../lib/util"
	import {caption} from "../lib/caption";
	import {Validator} from "../lib/validator";
	import Error from "./Error.svelte";
	import UploadImages from "./UploadImages.svelte";

	export let action
	export let categories

	let formElem
	let customExpenseElem
	let additionalMemberElem
	let lastAdditionalMemberElem
	let validator = new Validator({})
	let disabled = false
	let formData = {
		projectTitle: "",
		categoryID: "",
		amountLevelID: "",
		description: "",
		website: "",
		socialMedia: "",
		amount: "",
		projectType: "",
		firstname: "",
		name: "",
		street: "",
		zipplace: "",
		email: "",
		phone: "",
		yearOfBirth: "",
		expenses: [
			{id: "rent", name: "Raummiete", amount: ""},
			{id: "material", name: "Material (Miete oder Kauf)", amount: ""},
			{id: "advertising", name: "Werbung", amount: ""},
			{id: "other", name: "Anderes", amount: ""},
		],
		customExpenses: [],
		funds: [
			{id: "own-funds", name: "Eigenmittel", amount: ""},
			{id: "support", name: "Unterstützung", amount: ""},
		],
		additionalMembers: [],
		files: [],
	}

	$: amountLevel = amountLevels.find(l => l.id === formData.amountLevelID) ?? amountLevels[0]
	$: descriptionLength = formData.description.trim().length
	$: badDescriptionLength = descriptionLength < amountLevel.description.min
			|| descriptionLength > amountLevel.description.max
	$: canHaveBudget = formData.amountLevelID >= 2
	$: canHaveAdditionalMembers = formData.projectType === "group"
	$: allExpenses = formData.expenses.concat(formData.customExpenses)
			.reduce((sum, expense) => sum + toInt(expense.amount), 0)
	$: allFunds = formData.funds
			.reduce((sum, fund) => sum + toInt(fund.amount), 0)
	$: balance = allFunds + toInt(formData.amount) - allExpenses
	$: balanceError = balance === 0 ? null : balance > 0
			? `Derzeit übersteigen die Geldmittel den Aufwand um CHF ${balance}`
			: `Derzeit übersteigt der Aufwand die Geldmittel um CHF ${-balance}`

	async function onAddExpense() {
		formData.customExpenses = [...formData.customExpenses, {}]
		await tick();
		scrollIntoView(customExpenseElem, 20, 20, () => customExpenseElem.focus())
	}

	function onRemoveExpense(atIndex) {
		formData.customExpenses.splice(atIndex, 1)
		formData.customExpenses = formData.customExpenses
	}

	async function onAddMember() {
		formData.additionalMembers = [...formData.additionalMembers, {}]
		await tick();
		scrollIntoView([additionalMemberElem, lastAdditionalMemberElem], 20, 20, () => additionalMemberElem.focus())
	}

	async function onSubmit() {
		if (!validate(formData)) {
			await tick()
			scrollToError()
			sendDebuggingData(formData)
			return
		}

		const normalizedData = normalizeFormData(formData)
		disabled = true
		const response = await fetch(action, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(normalizedData),
		})
		if (response.ok) {
			const json = await response.json()
			disabled = false
			if (json.redirect) {
				await tick()
				location = json.redirect
			} else if (json.error) {
				validator.addError("submit", `Bei der Übertragung ist ein Fehler aufgetreten (${json.error}). Probieren Sie es später noch einmal`)
				validator = validator
				if (json.files) {
					Object.keys(json.files).forEach(name => {
						const file = formData.files.find(f => f.name === name)
						if (file) {
							file.hash = json.files[name]
						}
					})
				}
				await tick()
				scrollToError()
			}
		}
	}

	function sendDebuggingData(formData) {
		const normalizedData = normalizeFormData(formData)
		normalizedData.errors = validator.allErrors()
		normalizedData.files = normalizedData.files.map(f => ({
			name: f.name,
			data: f.data.substr(0, 100),
		}))
		// noinspection ES6MissingAwait
		fetch(action, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(normalizedData),
		})
	}

	function validate(formData) {
		validator = new Validator(formData)
		validator.checkNotBlank("projectTitle")
		validator.checkNotEmpty("categoryID")
		validator.checkNotEmpty("amountLevelID")
		validator.checkNotBlank("description")
		validator.checkLength("description", amountLevel.description.min, amountLevel.description.max)
		validator.checkNotEmpty("amount")
		validator.checkAmount("amount")
		validator.checkNotEmpty("projectType")
		validator.checkNotBlank("firstname")
		validator.checkNotBlank("name")
		validator.checkNotBlank("street")
		validator.checkNotBlank("zipplace")
		validator.checkZipPlace("zipplace")
		validator.checkNotBlank("email")
		validator.checkEmail("email")
		validator.checkNotBlank("phone")
		validator.checkPhone("phone")
		validator.checkNotBlank("yearOfBirth")
		validator.checkYearOfBirth("yearOfBirth")

		if (canHaveBudget) {
			formData.expenses.forEach((e, i) => {
				validator.checkNotEmpty(`expenses.${i}.amount`);
				validator.checkAmount(`expenses.${i}.amount`);
			})
			formData.funds.forEach((f, i) => {
				validator.checkNotEmpty(`funds.${i}.amount`);
				validator.checkAmount(`funds.${i}.amount`);
			})
			formData.customExpenses.forEach((e, i) => {
				validator.checkNotEmpty(`customExpenses.${i}.amount`)
				validator.checkAmount(`customExpenses.${i}.amount`)
				validator.checkNotBlank(`customExpenses.${i}.name`)
			})
		}

		let members = 0
		if (canHaveAdditionalMembers) {
			formData.additionalMembers.forEach((m, i) => {
				if (isAdditionalMemberObject(m)) {
					members++
					validator.checkNotBlank(`additionalMembers.${i}.firstname`)
					validator.checkNotBlank(`additionalMembers.${i}.name`)
					validator.checkNotEmpty(`additionalMembers.${i}.yearOfBirth`)
					validator.checkYearOfBirth(`additionalMembers.${i}.yearOfBirth`)
					validator.checkNotBlank(`additionalMembers.${i}.place`)
				}
			})
		}

		if (!validator.hasErrors()) {
			if (formData.projectType === "group" && members === 0) {
				validator.addError("additionalMembers", "Für ein Gruppenprojekt muss mindestens ein zusätzliches Projektmitglied angegeben werden.")
			}
		}

		if (balanceError) {
			validator.addError("balance", balanceError)
		}

		return !validator.hasErrors()
	}

	function normalizeFormData(formData) {
		const zipPlace = formData.zipplace.trim().split(/\s+/)
		return {
			magic: 4711,
			projectTitle: trimToNull(formData.projectTitle),
			categoryID: toIntOrNull(formData.categoryID),
			amountLevelID: toIntOrNull(formData.amountLevelID),
			description: trimToNull(formData.description),
			website: trimToNull(formData.website),
			socialMedia: trimToNull(formData.socialMedia),
			amount: toFloatOrNull(formData.amount),
			projectType: trimToNull(formData.projectType),
			firstname: trimToNull(formData.firstname),
			name: trimToNull(formData.name),
			street: trimToNull(formData.street),
			zip: toInt(zipPlace[0]),
			place: zipPlace.slice(1).join(" "),
			email: trimToNull(formData.email),
			phone: trimToNull(formData.phone),
			yearOfBirth: toIntOrNull(formData.yearOfBirth),
			expenses: formData.expenses.map(e => ({
				id: e.id,
				amount: toIntOrNull(e.amount),
			})),
			customExpenses: formData.customExpenses.map(e => ({
				name: trimToNull(e.name),
				amount: toIntOrNull(e.amount),
			})),
			funds: formData.funds.map(f => ({
				id: f.id,
				amount: toIntOrNull(f.amount),
			})),
			additionalMembers: formData.additionalMembers
				.filter(m => isAdditionalMemberObject(m))
				.map(m => ({
					firstname: trimToNull(m.firstname),
					name: trimToNull(m.name),
					yearOfBirth: toIntOrNull(m.yearOfBirth),
					place: trimToNull(m.place),
				})),
			files: formData.files.map(f => {
				if (f.hash) {
					return {
						name: f.name,
						hash: f.hash,
					}
				} else {
					return {
						name: f.name,
						data: f.data,
					}
				}
			}),
		}
	}

	function scrollToError() {
		const firstErrorElem = formElem.querySelector(".input-error, .balance-error")
		if (firstErrorElem) {
			const inputElem = firstErrorElem.nextElementSibling
			scrollIntoView([firstErrorElem, inputElem], 20, 20, () => {
				if (inputElem.matches("input, select")) {
					inputElem.focus();
				}
			})
		}
	}

	function isAdditionalMemberObject(value) {
		if (typeof value !== "object") {
			return false
		}
		return Validator.isNotBlank(value.firstname)
			|| Validator.isNotBlank(value.name)
			|| Validator.isNotEmpty(value.yearOfBirth)
			|| Validator.isNotBlank(value.place)
	}
</script>

<form name="register" method="post" action="gesuch.php" autocomplete="off" bind:this={formElem}>
	<label for="title">Projekttitel</label>
	<Error validator={validator} name="projectTitle"/>
	<input id="title" type="text" bind:value={formData.projectTitle} {disabled}>

	<label for="category">Kategorie</label>
	<Error validator={validator} name="categoryID"/>
	<select id="category" bind:value={formData.categoryID} {disabled}>
		<option value={null}></option>
		{#each categories as category}
			<option value={category.id}>{category.name}</option>
		{/each}
	</select>

	<label for="amount-level">Betrag</label>
	<Error validator={validator} name="amountLevelID"/>
	<select id="amount-level" bind:value={formData.amountLevelID} {disabled}>
		<option value={null}></option>
		{#each amountLevels as amountLevel}
			<option value={amountLevel.id}>{amountLevel.text}</option>
		{/each}
	</select>

	<label for="description">Beschrieb (was, warum, wie, wann, wo?)</label>
	<Error validator={validator} name="description"/>
	<textarea id="description" cols="40" rows="8"
			  placeholder={amountLevel.description.hint}
			  bind:value={formData.description} {disabled}></textarea>
	<div class="description-length" class:bad-length={badDescriptionLength}>{descriptionLength}</div>

	<label for="website">Website</label>
	<input id="website" type="url" bind:value={formData.website} {disabled}>

	<label for="social-media">Social Media</label>
	<textarea id="social-media" cols="40" rows="4" placeholder="Twitter, Facebook, Instagram, Bandscamp, …"
			  bind:value={formData.socialMedia} {disabled}></textarea>

	<label for="amount">Genauer angefragter Betrag aus Startstutz <span class="nowrap">(Aufwand − Geldmittel)</span></label>
	<Error validator={validator} name="amount"/>
	<input id="amount" type="number" placeholder="Ganzzahliger Frankenbetrag"
		   bind:value={formData.amount} {disabled}>

	{#if canHaveBudget}
		<h3>Budget</h3>
		<!--suppress XmlInvalidId -->
		<label for="expense1" class="sublabel">Aufwand</label>
		{#each formData.expenses as expense, index}
			<Error validator={validator} name={`expenses.${index}.amount`}/>
			<input id={index === 0 ? "expense1" : null} type="number" placeholder={expense.name}
				   use:caption bind:value={expense.amount} {disabled}>
		{/each}
		{#each formData.customExpenses as expense, index}
			<div class="custom-expense">
				<div class="amount">
					<Error validator={validator} name={`customExpenses.${index}.amount`}/>
					<input type="number" placeholder="Betrag"
						   bind:value={expense.amount} bind:this={customExpenseElem} {disabled}>
				</div>
				<div class="name">
					<Error validator={validator} name={`customExpenses.${index}.name`}/>
					<input type="text" placeholder="Bezeichnung" bind:value={expense.name} {disabled}>
				</div>
				<button type="button" class="button-remove" {disabled}
						on:click={() => onRemoveExpense(index)}>Entfernen</button>
			</div>
		{/each}
		<div class="all-expenses">Summe der Aufwände: <b>CHF {allExpenses}</b></div>
		<div class="buttons">
			<button type="button" class="button-add" {disabled}
					on:click={onAddExpense}>Weiteren Aufwandposten hinzufügen</button>
		</div>

		<!--suppress XmlInvalidId -->
		<label for="fund1" class="sublabel">Geldmittel</label>
		{#each formData.funds as fund, index}
			<Error validator={validator} name={`funds.${index}.amount`}/>
			<input id={index === 0 ? "fund1" : null} type="number" placeholder={fund.name}
				   use:caption bind:value={fund.amount} {disabled}>
		{/each}
		<div class="all-funds">Summe der Geldmittel: <b>CHF {allFunds}</b></div>

		{#if balanceError}
			<div class="balance-error">
				Das Budget muss ausgeglichen sein (Beitrag Startstutz + Geldmittel − Aufwand = 0)<br>
				{balanceError}
			</div>
		{/if}
	{/if}

	<label for="project-type-individual">Mitwirkende</label>
	<Error validator={validator} name="projectType"/>
	<div class="radio-group">
		<input id="project-type-individual" type="radio" value="individual"
			   bind:group={formData.projectType} {disabled}>
		<label for="project-type-individual">Einzelprojekt</label>
		<input id="project-type-group" type="radio" value="group"
			   bind:group={formData.projectType} {disabled}>
		<label for="project-type-group">Gruppenprojekt (alle Mitglieder)</label>
	</div>

	<label for="firstname">Kontaktdaten</label>
	<Error validator={validator} name="firstname"/>
	<input id="firstname" type="text" placeholder="Vorname"
		   use:caption bind:value={formData.firstname} {disabled}>
	<Error validator={validator} name="name"/>
	<input id="name" type="text" placeholder="Name"
		   use:caption bind:value={formData.name} {disabled}>
	<Error validator={validator} name="street"/>
	<input id="street" type="text" placeholder="Strasse und Nummer" data-placeholder="Strasse/Nr."
		   use:caption bind:value={formData.street} {disabled}>
	<Error validator={validator} name="zipplace"/>
	<input id="zipplace" type="text" placeholder="Postleitzahl und Ort" data-placeholder="PLZ/Ort"
		   use:caption bind:value={formData.zipplace} {disabled}>
	<Error validator={validator} name="email"/>
	<input id="email" type="email" placeholder="E-Mail"
		   use:caption bind:value={formData.email} {disabled}>
	<Error validator={validator} name="phone"/>
	<input id="phone" type="text" placeholder="Telefon"
		   use:caption bind:value={formData.phone} {disabled}>
	<Error validator={validator} name="yearOfBirth"/>
	<input id="year-of-birth" type="number" placeholder="Geburtsjahr"
		   use:caption bind:value={formData.yearOfBirth} {disabled}>

	{#if canHaveAdditionalMembers}
		{#each formData.additionalMembers as member, index}
			<label for={`firstname${index + 1}`}>Projektmitglied {index + 2}</label>
			<Error validator={validator} name={`additionalMembers.${index}.firstname`}/>
			<input id={`firstname${index + 1}`} type="text" maxlength="50" placeholder="Vorname"
				   use:caption bind:value={member.firstname} bind:this={additionalMemberElem} {disabled}>
			<Error validator={validator} name={`additionalMembers.${index}.name`}/>
			<input type="text" maxlength="50" placeholder="Name"
				   use:caption bind:value={member.name} {disabled}>
			<Error validator={validator} name={`additionalMembers.${index}.yearOfBirth`}/>
			<input type="number" placeholder="Geburtsjahr"
				   use:caption bind:value={member.yearOfBirth} {disabled}>
			<Error validator={validator} name={`additionalMembers.${index}.place`}/>
			<input type="text" maxlength="50" placeholder="Wohnort"
				   use:caption bind:value={member.place} bind:this={lastAdditionalMemberElem} {disabled}>
		{/each}

		<div class="buttons">
			<Error validator={validator} name="additionalMembers"/>
			<button type="button" class="button-add" on:click={onAddMember} {disabled}>
				Weiteres Projektmitglied hinzufügen
			</button>
		</div>
	{/if}

	<!-- svelte-ignore a11y-label-has-associated-control -->
	<label>Zusätzliche Dokumente</label>
	<UploadImages bind:files={formData.files} {disabled}/>

	<div class="buttons">
		<button type="submit" class="button-large button-submit" on:click|preventDefault={onSubmit}
				{disabled} class:progress={disabled}>Ab gehts</button>
		<Error validator={validator} name="submit" class="submit-error"/>
	</div>
</form>
