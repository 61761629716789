export const defaultAnimationDuration = 300

export function easeLinear(t) {
    return t
}

export function easeInOutQuad(t) {
    return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
}

export function easeInOutCubic(t) {
    return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
}

export function ease(startValue, endValue, timeMillis, easingFunc, callback, endCallback = null) {
    let startTime
    const valueDiff = endValue - startValue

    function step(timestamp) {
        timestamp = timestamp || new Date().getTime()
        if (!startTime) {
            startTime = timestamp
        }
        let timeDiff = timestamp - startTime
        if (timeDiff >= timeMillis) {
            callback(endValue)
            endCallback?.()
        } else {
            const t = timeDiff / timeMillis
            const value = startValue + easingFunc(t) * valueDiff
            callback(value)
            window.requestAnimationFrame(step)
        }
    }

    window.requestAnimationFrame(step)
}
