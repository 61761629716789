import "regenerator-runtime/runtime"
import RegistrationForm from "./components/RegistrationForm.svelte"

document.addEventListener("DOMContentLoaded", function() {
    // Make touch devices styleable from CSS
    if ("ontouchstart" in window) {
        document.querySelector("html").classList.add("touch")
    }

    handleMenuButton()
    handleRegistrationForms()
    handleFilterForms()
})

function handleMenuButton() {
    const html = document.querySelector("html")
    const menu = document.querySelector("header ul")
    let hideTimeout

    document.querySelector(".menu-button").addEventListener("click", function() {
        if (html.classList.contains("menu")) {
            hide()
        } else {
            show()
        }
    })

    document.addEventListener("mousedown", onMouseDown)
    document.addEventListener("touchstart", onMouseDown)

    function onMouseDown(e) {
        if (html.classList.contains("menu")) {
            if (e.target.closest("header ul, .menu-button") === null) {
                hide()
            }
        }
    }

    function hide() {
        if (html.classList.contains("menu")) {
            html.classList.remove("menu-expanded")
            hideTimeout = setTimeout(() => html.classList.remove("menu"), 300)
        }
    }

    function show() {
        clearTimeout(hideTimeout)
        html.classList.add("menu")
        window.getComputedStyle(menu).width
        html.classList.add("menu-expanded")
    }
}

function handleRegistrationForms() {
    const elems = document.querySelectorAll(".registration-form")
    for (const elem of elems) {
        const action = elem.dataset.action
        const categories = JSON.parse(elem.dataset.categories) ?? []
        new RegistrationForm({
            target: elem,
            props: {
                action: action,
                categories: categories,
            }
        })
    }
}

function handleFilterForms() {
    const form = document.querySelector("form[name=filter]")
    if (form) {
        const projects = getProjectsList(form)
        form.querySelector("select").addEventListener("change", function() {
            const categoryId = parseInt(this.value) || null
            for (const project of projects) {
                const projectCategoryId = parseInt(project.dataset.categoryId)
                if (categoryId == null || projectCategoryId === categoryId) {
                    project.style.display = ""
                } else {
                    project.style.display = "none"
                }
            }
        })
    }
}

function getProjectsList(form) {
    let nextSibling = form.nextElementSibling
    while (nextSibling && !nextSibling.classList.contains("projects")) {
        nextSibling = nextSibling.nextElementSibling
    }
    return nextSibling?.querySelectorAll(".project") ?? []
}