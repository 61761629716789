import {defaultAnimationDuration, ease, easeInOutQuad} from "./anim";

export function scrollIntoView(elem, extraMarginTop = 0, extraMarginBottom = 0, onComplete = null) {
    const elems = Array.isArray(elem) ? elem : [elem]
    let elemsTop = Number.MAX_VALUE
    let elemsBottom = Number.MIN_VALUE

    for (let i = 0; i < elems.length; i++) {
        const elem = elems[i]
        if (elem) {
            const bounds = elem.getBoundingClientRect()
            elemsTop = Math.min(elemsTop, bounds.top)
            elemsBottom = Math.max(elemsBottom, bounds.bottom)
        }
    }

    elemsTop -= extraMarginTop
    elemsBottom += extraMarginBottom

    let diff = 0
    if (elemsBottom > window.innerHeight) {
        diff = elemsBottom - window.innerHeight
        elemsTop -= diff
    }
    if (elemsTop < 0) {
        diff += elemsTop
    }

    const scrollTop = window.pageYOffset + diff
    ease(window.pageYOffset, scrollTop, defaultAnimationDuration, easeInOutQuad, function(value) {
        window.scrollTo(0, value)
    }, onComplete)
}

export function toInt(value) {
    return parseInt(value) || 0
}

export function toIntOrNull(value) {
    value = parseInt(value)
    return isNaN(value) ? null : value
}

export function toFloatOrNull(value) {
    value = parseFloat(value)
    return isNaN(value) ? null : value
}

export function trimToNull(value) {
    if (value === null || typeof value === "undefined") {
        return null
    }
    value = String(value).trim()
    return value !== "" ? value : null
}