export function caption(elem) {
    const toggleVisibility = () => {
        if (elem.value !== "") {
            captionElem.classList.add("visible")
        } else {
            captionElem.classList.remove("visible")
        }
    }

    const layout = () => {
        const bounds = elem.getBoundingClientRect()
        const elemLeft = bounds.left
        const elemTop = bounds.top + window.pageYOffset
        const captionElemLeft = elemLeft + elem.offsetWidth - captionElem.offsetWidth
        const captionElemTop = elemTop + (elem.offsetHeight - captionElem.offsetHeight) / 2
        captionElem.style.left = `${captionElemLeft}px`
        captionElem.style.top = `${captionElemTop}px`
        requestAnimationFrame(layout)
    }

    const captionElem = document.createElement("span")
    captionElem.className = "caption hidden"
    captionElem.textContent = elem.dataset.placeholder || elem.getAttribute("placeholder")

    elem.addEventListener("input", toggleVisibility)
    document.body.appendChild(captionElem)
    toggleVisibility()
    requestAnimationFrame(layout)

    return {
        destroy() {
            elem.removeEventListener("input", toggleVisibility)
            document.body.removeChild(captionElem)
        }
    }
}